<template>
  <div>
    <!-- 添加资源 -->
    <div class="box">
      <div class="title_box flex">
        <Title :title="$route.query.id ? '编辑资源' : '添加资源'" />

      </div>
      <div class="ct_box">
        <ul class="flex navList">
          <li @click="routeFn(item.id)" :class="$store.state.active == item.id ? 'active' : ''"
            v-for="(item, index) in navList" :key="index">
            {{ item.title }}</li>
        </ul>
        <div class="form_box">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import { mapState } from 'vuex'
export default {
  components: {
    Title,
  },
  computed: {
    ...mapState(['active', 'AddRsId','AddRs','pubNav'])
  },
  mounted() {
   this.getNav()

  },
  data() {
    return {
      navList: []
    }
  },
  methods: {
    getNav() {
      this.curlGet('/api/medium_category/list').then(res => {
        if (res.data.code) {
          this.navList = res.data.data
        }
      })
    },
    routeFn(index) {
      this.$store.state.active = index
      this.$store.state.pubNav = index
      switch (index) {
        case 1:
          this.$router.push({
            path: '/user/AddResource/News',

          })
          break;
        case 2:
          this.$router.push({
            path: '/user/AddResource/WeMedia',

          })
          break;
        case 3:
          this.$router.push({
            path: '/user/AddResource/PaperMedia',

          })
          break;
        case 4:
          this.$router.push({
            path: '/user/AddResource/OfficialAccounts',

          })
          break;
        case 5:
          this.$router.push({
            path: '/user/AddResource/Wb',

          })
          break;
        case 6:
          this.$router.push({
            path: '/user/AddResource/RedBook',

          })
          break;
        case 7:
          this.$router.push({
            path: '/user/AddResource/ShortVideo',

          })
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/AddResource.scss';
</style>